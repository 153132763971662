import * as React from 'react'
import { graphql } from 'gatsby'
import { Container, makeStyles, Theme, createStyles, Typography } from '@material-ui/core'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import IndexLayout from '../templates/IndexLayout'

interface BlogPostTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    markdownRemark: {
      html: string
      excerpt: string
      frontmatter: {
        title: string
        description: string
        featured_image: any
      }
    }
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mdxSection: {
      '& .gatsby-resp-image-wrapper': {
        maxWidth: '300px !important',
        height: 'auto !important',
        textAlign: 'left'
      },
      '& img': {
        maxWidth: '100%'
      }
    },
    mdxHeader: {
      fontSize: '2.5rem',
      textAlign: 'center',
      fontFamily: 'Montserrat',
      padding: '2vh'
    }
  })
)

const BlogPostTemplate: React.SFC<BlogPostTemplateProps> = ({ data }) => {
  console.log(data.markdownRemark)
  console.log("hello")
  const classes = useStyles()
  return (
    <IndexLayout title={data.markdownRemark.frontmatter?.title} description={data.markdownRemark.frontmatter?.description} imageUrl={data.markdownRemark.frontmatter?.featured_image?.childImageSharp?.gatsbyImageData.images.fallback.src}>
      <div style={{ height: '5vh' }} />
      <Container maxWidth="md">
        <Typography variant="h6" className={classes.mdxHeader}>
          {data.markdownRemark.frontmatter?.title}
        </Typography>
        <Container maxWidth="xs">
          <GatsbyImage alt="" image={data.markdownRemark.frontmatter?.featured_image?.childImageSharp?.gatsbyImageData} />
        </Container>
        <div className={classes.mdxSection} dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
      </Container>
    </IndexLayout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    site {
      siteMetadata {
        title
        description
        author {
          name
          url
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      frontmatter {
        title
        description
        featured_image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
